.signup {
  //background: 'bg-default';
  background-image: url('https://firebasestorage.googleapis.com/v0/b/appt-business.appspot.com/o/global%2FSign_Up_Background_1.png?alt=media&token=cc963856-d085-4a11-bf35-63385a25368b');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;  

  .signup-card {
    max-width: 400px;
    border-radius: 12px !important;
    // img {
    //   width: 200px;
    // }
  }

  .select-business-on-login {
    max-width: 300px;
    border-radius: 12px !important;
    margin-top: 300px;
    // img {
    //   width: 200px;
    // }
  }

  .create-first-business {
    max-width: 750px;
    border-radius: 12px !important;
    // img {
    //   width: 200px;
    // }
  }
}
