//#root, //done to fix scroll issues
//body, //done to fix scroll issues
html {
  width: 100%;
  height: 100%;
  overflow: auto; //changed from 'hidden' - note no '' on prod value
  //position: relative;
}

body { height: 100%; }

div,
a {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6,
.card-title {
  color: $text-body //!important; // removed important tag here for timepicker compatability
  // font-weight: 500;
}

.layout1,
.layout2,
.MuiPaper-root,
.MuiTableCell-body,
.matx-customizer {
  color: $text-body !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  color: rgba(255, 0, 221, 0.863);
  font-size: 16px;
}